<template>
  <nav id="nav" class="nav">
    <ul class="nav-list">
      <li v-for="(item, index) in navItems" :key="item" :class= "{'has-submenu' : item.child.length}">
        <router-link :to="item.router" v-if="item.router.length && !item.child.length">{{item.name}}</router-link>
        <a v-if="!item.router.length && !item.child.length">{{item.name}}</a>

        <a  v-on:click.stop.prevent="showMenu(index); hideMenu" v-if="item.child.length" >{{item.name}} <span class="submenu-arrow"></span></a>
        <transition name="fade">
        <div class="submenu" v-show="indexShow === index" v-if="item.child.length">
          <ul>
             <li v-for="child in item.child" :key="child">
                <router-link :to="child.router" >{{child.name}}</router-link>
              </li>
          </ul>
        </div>
        </transition>
      </li>
    </ul>
  

  </nav>
</template>

<script>
export default {
  props: {
        nav: Object
      },
  data() {
     return { 
      indexShow: null,
      navItems: this.nav
    }
  },
   watch: {
      '$route' () {
        this.indexShow = ''
      }
  },
   methods:{
    showMenu: function(n) {
      if(this.indexShow === n ){
            this.indexShow = ''
          } 
          else {
            this.indexShow = n;
          }
    },
    hideMenu: function(event) {
      if (!this.$el.contains(event.target)){
        this.indexShow = ''
      }

    }
  },
   mounted () {
    document.addEventListener('click', this.hideMenu)
     document.addEventListener('mouseleave', this.hideMenu)
  },
  beforeUnmount () {
    document.removeEventListener('click',this.hideMenu)
    document.addEventListener('mouseleave', this.hideMenu)
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import "../assets/scss/components/nav.scss";
</style>
