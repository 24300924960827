<template>
  <header-vue/>
      <router-view/>

  <footer-vue/>
</template>
<script>
  import Header from '@/components/Header.vue'

  import { defineAsyncComponent } from 'vue'

   const Footer = defineAsyncComponent(() =>
    import('@/components/Footer.vue')
  )
  
  export default {
    components: {
      'header-vue' : Header,
      'footer-vue' : Footer,
    },
     data () {
      return {
      }
    },
    watch: {
      '$route' (to) {
        document.title = to.meta.title || 'Lemberg Flowers'
        document.querySelector('head meta[name="description"]').setAttribute('content', to.meta.description)

        let fullHref = window.location.protocol + "//" + window.location.host + this.$route.fullPath

        document.querySelector('head link[rel="canonical"]').setAttribute('href', fullHref)
      }
    }
  }
</script>
<style lang="scss">
</style>
