<template>
	<div class="content">
		<section 
		data-aos="opacity-animation" 
			data-aos-duration="2000"
			class="slide-section">
			<div class="slider-container">
			<picture class="slide-bg">
				<source srcset="../assets/images/banner-before.webp" type="image/webp">
				<img src="../assets/images/banner-before.jpg" alt="banner image" :ref="setImageSizeRef" />
			</picture>
			</div>
			<div class="slider-container">
			<picture class="slide-bg">
				<source srcset="../assets/images/banner-after.webp" type="image/webp">
				<img src="../assets/images/banner-after.jpg"  alt="banner image" :ref="setImageSizeRef">
			</picture>
			<picture class="animate-img after-1">
				<source srcset="../assets/images/after3-Recovered.webp" type="image/webp">
				<img src="../assets/images/after3-Recovered.png"  alt="banner image" :ref="setImageSizeRef">
			</picture>
			<picture class="animate-img after-2">
				<source srcset="../assets/images/after4-Recovered.webp" type="image/webp">
				<img src="../assets/images/after4-Recovered.png"  alt="banner image" :ref="setImageSizeRef">
			</picture>
			</div>
			<div class="slider-text-wraper">
				<div class="slider-text">
					<h1>
						<div v-for="(text, index) in sliderContent" :key="text">
							<span :class="'text-item text-item-'+index" 
							data-aos="fade-right"
							:data-aos-delay="index*3 + '00'"
							data-aos-duration="1000"
							><span data-aos="opacity-animation" 
							data-aos-delay="900"
							data-aos-duration="1000"
							>{{text}}</span></span>
						</div>
					</h1>
				</div>
			</div>
			<div class="nav-wrap">
				<header-nav :nav="mobileNav" v-if="isMobile" class="main-nav-buttons"></header-nav>
			</div>
		</section>
		<section class="services-section">
			<div class="container services">
				<h2 class="title">Наші послуги</h2>
				<div class="services-wrap">
					<div class="services-container">
						<div v-for="(service, index) in services" :key="service" 
						:class="'service-item service-'+index">
							<div
							v-if="index == 1"
							data-aos="fade-right"
							data-aos-duration="1000">
								<router-link :to="service.path"
								v-bind:class="{hover: index === selectedService }"
								>
									<h2 class="service-link" v-on:mouseover="divMousedown(index, $event)" :value="index"
									>{{service.title}}</h2>	
								</router-link>
							</div>
							<div v-else
							data-aos="fade-left"
							data-aos-duration="1000">
							<router-link :to="service.path"
							v-bind:class="{hover: index === selectedService }"
							
							>
								<h2 class="service-link" v-on:mouseover="divMousedown(index, $event)" :value="index"
								>{{service.title}}</h2>	
							</router-link>
							</div>
							
								<div v-if="!isMobile" class="service-img-wrap" v-bind:class="{hover: index === selectedService }"
								
								>
								<div
								:data-aos="fadeImageService(index)"
									data-aos-easing="linear" 									data-aos-duration="1500" class="animate-img">
									<picture v-for="(image, index) in service.images" :key="image" class="picture">
										<source :srcset="service.webp[index]" type="image/webp">
										<img  :src="image" alt="image service"
									:ref="setImageSizeRef">
									</picture>
									
									</div>
								</div>
							<div v-if="!isMobile">
								<p v-if="index === 0"
								data-aos="fade-right"
								data-aos-easing="linear" 
								data-aos-delay="1900"									data-aos-duration="5000"
									class="services-text">{{service.text}}</p>
									<p v-else
									class="services-text">{{service.text}}</p>
								</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="about-us">
			<div class="tricer"></div>
			<div class="container cols-2">
				<div class="content">
					<h2 
					data-aos="fade-right"
					data-aos-delay="300"
					data-aos-duration="1000"
					class="title">Lemberg Flowers</h2>
					<div
					data-aos="fade-left"
					data-aos-delay="300"
					data-aos-duration="1000"
					>
						<p>Ми робимо фітодизайн інтер'єрів: офісних приміщень, ресторанів, готелів, магазинів, ТЦ та приватних помешкань. Крім того здійснюємо зовнішнє озеленення балконів, літніх майданчиків кафе та ресторанів.</p>
						<p>У нашому садовому центрі маємо великий вибір товарів гуртом та в роздріб.</p>
					</div>
					<router-link 
					data-aos="fade-right"
					data-aos-delay="300"
					data-aos-duration="1000"

					to="/about" class="btn" title="Про Lemberg Flowers">ПРО LEMBERG FLOWERS</router-link>
				</div>
				<div class="left-images">
					<picture
					data-aos="fade-left-img"
					data-aos-delay="100"
					data-aos-duration="1000" class="img-center">
						<source srcset="../assets/images/about1-mob.jpg" type="image/jpg" media="(max-width: 600px)">
						<source srcset="../assets/images/about1-mob.webp" type="image/webp" media="(max-width: 600px)">
						
						<source srcset="../assets/images/about1.webp" type="image/webp">
						<img src="../assets/images/about1.jpg" alt="img flower" :ref="setImageSizeRef" >
					</picture>
					
					<picture
					data-aos="fade-left-img"
					data-aos-delay="100"
					data-aos-duration="1000" class="img-large">
						<source srcset="../assets/images/about2-mob.jpg" type="image/jpg" media="(max-width: 600px)">
						<source srcset="../assets/images/about2-mob.webp" type="image/webp" media="(max-width: 600px)">
						
						<source srcset="../assets/images/about2.webp" type="image/webp">
						<img  src="../assets/images/about2.jpg" alt="img flower" :ref="setImageSizeRef" >
					</picture>
				</div>
			</div>
		</section>
		<section class="offers">
			<div class="container cols-2">
				<div class="offer-item">
					<div class="offer-item-wrap">
						<h3 
						data-aos="fade-up"
						data-aos-duration="1000"
						class="title">Траурна<br> флористика</h3>
						<p
						data-aos="fade-up"
						data-aos-duration="1000" >Створюємо квіткові композиції на похорон – це екологічна альтернатива пластиковим вінкам. Такий вид флористики має певні правила, тому краще звернутися до професіоналів, щоб композиція виглядала доречною. Наші дизайнери складуть правильний букет або кошик, підберуть спеціальні квіти у необхідній кольоровій гамі.</p>
						<div class="offers-img-container images-2">
							<picture>						
								<source srcset="../assets/images/offers1.webp" type="image/webp">
								<img src="../assets/images/offers1.jpg" alt="img offer" :ref="setImageSizeRef" >
							</picture>
							<picture>						
								<source srcset="../assets/images/offers2.webp" type="image/webp">
								<img src="../assets/images/offers2.jpg" alt="img offer" :ref="setImageSizeRef" >
							</picture>
						</div>
						<div class="button-wrap">
							<router-link 
							data-aos="fade-right"
							data-aos-duration="1000"
							to="/mourning-floristics" class="btn" title="Траурна флористика">ДЕТАЛЬНІШЕ</router-link>
						</div>
					</div>
				</div>
				<div class="offer-item">
					<div class="offer-item-wrap">
						<h3 class="title"
						data-aos="fade-up"
						data-aos-duration="1000">Фотозона в садовому центрі</h3>
						<p
						data-aos="fade-up"
						data-aos-duration="1000">Ми відкриті для всіх! Приїжджайте по рослини або навіть просто так. Організовуйте фотозйомки в нашому садовому центрі серед сотень квітів.</p>
						<div class="offers-img-container images-3">
							<picture>						
								<source srcset="../assets/images/offers3.webp" type="image/webp">
								<img src="../assets/images/offers3.jpg" alt="img offer" :ref="setImageSizeRef" >
							</picture>
							<picture>						
								<source srcset="../assets/images/offers4.webp" type="image/webp">
								<img src="../assets/images/offers4.jpg" alt="img offer" :ref="setImageSizeRef" >
							</picture>
							<picture>						
								<source srcset="../assets/images/offers5.webp" type="image/webp">
								<img src="../assets/images/offers5.jpg" alt="img offer" :ref="setImageSizeRef" >
							</picture>
						</div>
						<div class="button-wrap">
							<router-link 
							data-aos="fade-right"
							data-aos-duration="1000"
							to="/photo-zone" class="btn" title="Фотозона в Садовому центрі">ДЕТАЛЬНІШЕ</router-link>
						</div>
					</div>
				</div>
				<div class="offer-item default">
					<div class="offer-item-wrap">
						<h3 class="title"
						data-aos="fade-up"
						data-aos-duration="1000">Екскурсії для дітей</h3>
						<p
						data-aos="fade-up"
						data-aos-duration="1000">Організовуємо екскурсії для школярів. Показуємо дітям наш садовий центр та пояснюємо основні моменти та складнощі вирощування такої великої кількості рослин.</p>
						<div class="offers-img-container">
							<picture>						
								<source srcset="../assets/images/offers6.webp" type="image/webp">
								<img src="../assets/images/offers6.jpg" alt="img offer" :ref="setImageSizeRef" >
							</picture>
							<picture>						
								<source srcset="../assets/images/offers7.webp" type="image/webp">
								<img src="../assets/images/offers7.jpg" alt="img offer" :ref="setImageSizeRef" >
							</picture>
						</div>
					</div>
				</div>
				<div class="offer-item default default-rever">
					<div class="offer-item-wrap">
						<div class="offers-img-container">
							<picture>						
								<source srcset="../assets/images/offers8.webp" type="image/webp">
								<img src="../assets/images/offers8.jpg" alt="img offer" :ref="setImageSizeRef" >
							</picture>
							<picture>						
								<source srcset="../assets/images/offers9.webp" type="image/webp">
								<img src="../assets/images/offers9.jpg" alt="img offer" :ref="setImageSizeRef" >
							</picture>
						</div>
						<p
						data-aos="fade-up"
						data-aos-duration="1000">Для кожної групи проводимо майстер-клас з посадки рослин у горщики, які діти потім отримують у якості подарунка.</p>
						<div class="button-wrap">
							<router-link 
							data-aos="fade-right"
							data-aos-duration="1000"
							to="/kids-excursions" class="btn" title="Екскурсії для дітей">ДЕТАЛЬНІШЕ</router-link>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!--section class="events-section">
			<div class="container">
				<h2 class="title">ПОДІЇ</h2>
				<v-events class="white-color" count="3" ></v-events>
			</div>
			
		</section-->
		<section class="gallery">
			<div class="container container-gallery">
				<div>
					<picture>
						<source srcset="../assets/images/main-gallery/main-gallery1-mob.jpg" type="image/jpg" media="(max-width: 440px)">
						<source srcset="../assets/images/main-gallery/main-gallery1-mob.webp" type="image/webp" media="(max-width: 440px)">
						<source srcset="../assets/images/main-gallery/main-gallery1.webp" type="image/webp">
						<img src="../assets/images/main-gallery/main-gallery1.jpg" alt="img flower" :ref="setImageSizeRef" >
					</picture>
					
				</div>
				<div>
					<picture>
						<source srcset="../assets/images/main-gallery/main-gallery2-mob.jpg" type="image/jpg" media="(max-width: 440px)">
						<source srcset="../assets/images/main-gallery/main-gallery2-mob.webp" type="image/webp" media="(max-width: 440px)">
						<source srcset="../assets/images/main-gallery/main-gallery2.webp" type="image/webp">
						<img src="../assets/images/main-gallery/main-gallery2.jpg" alt="img flower" :ref="setImageSizeRef" >
					</picture>
					<picture>						
						<source srcset="../assets/images/main-gallery/main-gallery3.webp" type="image/webp">
						<img src="../assets/images/main-gallery/main-gallery3.jpg" alt="img flower" :ref="setImageSizeRef">
					</picture>
				</div>
				<div class="space-between">
					<picture>						
						<source srcset="../assets/images/main-gallery/main-gallery4.webp" type="image/webp">
						<img src="../assets/images/main-gallery/main-gallery4.jpg" alt="img flower" :ref="setImageSizeRef" >
					</picture>
					<picture>						
						<source srcset="../assets/images/main-gallery/main-gallery5.webp" type="image/webp">
						<img src="../assets/images/main-gallery/main-gallery5.jpg" alt="img flower" :ref="setImageSizeRef">
					</picture>
				</div>
				<div>
					<picture>
						<source srcset="../assets/images/main-gallery/main-gallery6-mob.jpg" type="image/jpg" media="(max-width: 440px)">
						<source srcset="../assets/images/main-gallery/main-gallery6-mob.webp" type="image/webp" media="(max-width: 440px)">	
						<source srcset="../assets/images/main-gallery/main-gallery6.webp" type="image/webp">
						<img src="../assets/images/main-gallery/main-gallery6.jpg" alt="img flower" :ref="setImageSizeRef">
					</picture>
				</div>
			</div>
		</section>
		<section>
			<v-contacts/>
		</section>
	</div>
</template>

<style scoped lang="scss">
	@import "../assets/scss/components/home-page.scss";
</style>
<script>

import HeaderNav from '@/components/HeaderNav.vue'
//import Events from '@/components/Events.vue'
import Contacts from '@/components/Contacts.vue'

export default {
  components: {
//	'v-events':Events,
	'v-contacts': Contacts,
	'header-nav' : HeaderNav,
  },

  data(){
	return{
		sliderContent: [],
		sliderContentDesctop: [
			'Комплексне озеленення інтер\'єрів та',
			'екстер\'єрів житлових і комерційних',
			'приміщень "під ключ"'
		],
		sliderContentMobile: [
			'Комплексне озеленення ',
			'інтер\'єрів та екстер\'єрів',
			'житлових і комерційних',
			'приміщень "під ключ"'
		],
		mobileNav: [
        {
          router:'/about',
          name:'Про нас',
          child:[]
        },
        {
          router:'/events',
          name:'Події',
          child:[]
        },
        {
          router:'#contacts',
          name:'Контакти',
          child:[]
        },
        {
          router:'/greening-of-offices',
          name:'Озеленення офісів',
          child:[]
        },
        {
          router:'/balcony-gardening',
          name:'Балконне озеленення',
          child:[]
        },
        {
          router:'/mourning-floristics',
          name:'Траурна флористика',
          child:[]
        },
        {
          router:'/photo-zone',
          name:'Фотозона',
          child:[]
        },
        {
          router:'/kids-excursions',
          name:'Дитячі екскурсії',
          child:[]
        },
         {
          router:'/landscape-design',
          name:'Ландшафтний дизайн',
          child:[]
        }
      ],
		services:[
			{
				title: 'Озеленення офісів',
				path: '/greening-of-offices',
				text: 'Внутрішнє озеленення офісних приміщень, продумане до деталей фітодизайнером',
				images: [require('../assets/images/service.jpg'), 
						require('../assets/images/service1.jpg'), 
						require('../assets/images/service2.jpg')],
				webp: [require('../assets/images/service.webp'), 
						require('../assets/images/service1.webp'), 
						require('../assets/images/service2.webp')],
				active: 'hover'
			},
			{
				title: 'Балконне озеленення',
				path: '/balcony-gardening',
				text: 'Квіткові композиції для балконів та терас готелів, ресторанів і приватних будинків: понад 300 варіацій оформлення',
				images: [require('../assets/images/service2-1.jpg'),
						require('../assets/images/service2-2.jpg'),
						require('../assets/images/service2-3.jpg')],

				webp: [
				require('../assets/images/service2-1.webp'),
						require('../assets/images/service2-2.webp'),
						require('../assets/images/service2-3.webp')]
			},
			{
				title: 'Ландшафтний дизайн',
				path: '/landscape-design',
				text: 'Озеленення прибудинкової чи комерційної території, висадка дерев у саду: проєктування, реалізація, консультації по догляду за рослинами',
				images: [require('../assets/images/service3-1.jpg'),
						require('../assets/images/service3-2.jpg'),
						require('../assets/images/service3-3.jpg')],

				webp: [require('../assets/images/service3-1.webp'),
						require('../assets/images/service3-2.webp'),
						require('../assets/images/service3-3.webp')]
			}
		],
		hover: false,
		selectedService: 0,
		isMobile: false,
		imagesRefs: []
	}
  },
	mounted (){
		this.windowSize = window.innerWidth
		this.isMobileSize()
		this.sliderTitle()
	},
	methods:{
		divMousedown(n, event) {
			if(this.selectedService === n && !event.target){
				this.selectedService = ''
			}
			else {
				this.selectedService = n;
			}
		},
		fadeImageService(n){
			if(n === 0) {
				return 'fade-left'
			} else {
				return
			}

		},
		isMobileSize: function(){ 
			this.isMobile = this.windowSize < 1080 ? true : false
		},
		sliderTitle(){
			if(this.windowSize < 1080) {
				this.sliderContent = this.sliderContentMobile
			} else {
					this.sliderContent = this.sliderContentDesctop
			}
		},
		setImageSizeRef(el) {
			if (el) {
				this.imagesRefs.push(el)
			}
		}
	},
	beforeUpdate() {
		this.imagesRefs = []
	},
	updated() {
		setTimeout(() => {
			this.imagesRefs.forEach(function(entry) {

				entry.width = entry.naturalWidth
				entry.height = entry.naturalHeight
			});
		}, 300);
	}
}
</script>