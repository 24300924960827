import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/scss/style.scss'
import AOS from 'aos'
import 'aos/dist/aos.css'

import axios from 'axios'
import VueAxios from 'vue-axios'
import "./registerServiceWorker"


const app = createApp(App)

const AOSAnim = ({offset: 120})

app.use(router)
app.use(VueAxios, axios)
app.use(AOS.init, AOSAnim);
app.mount('#app')

