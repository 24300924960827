import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
   meta: {
      auth: false,
      title: 'Lemberg Flowers - озеленення інтер\'єрів та екстер\'єрів "під ключ"',
      description: 'Квіткове господарство, широкий асортимент вуличних та кімнатних рослин оптом та в роздріб. Озеленення офісів, ресторанів, готелів, а також ландшафтний дизайн.'
    },
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    meta: {
        auth: false,
        title: 'Квіткове господарство із найбільшим вибором рослин | Lemberg Flowers',
        description:'Комплексне зовнішнє озеленення та фітодизайн інтер\'єрів, а також продаж рослин у горщиках (гуртові ціни від 5000 грн.)'
      },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/greening-of-offices',
    name: 'Озеленення офісів',
    meta: {
        auth: false,
        title: 'Внутрішнє озеленення офісних приміщень у Львові, продумане до деталей фітодизайнером | Lemberg Flowers',
        description:'Ми не просто прикрашаємо офіси наявними в нас рослинами - ми підбираємо композиції під кожен конкретний об\'єкт, і при потребі вирощуємо необхідні рослини'
      },
    component: () => import(/* webpackChunkName: "greening-of-offices" */ '../views/GreeningOfOffices.vue')
  },
  {
    path: '/balcony-gardening',
    name: 'balcony-gardening',
     meta: {
        auth: false,
        title: 'Єдина компанія у Львові, яка комплексно займається озелененням балконів та терас | Lemberg Flowers',
        description:'З озелененням від команди Lemberg Flowers люди у своїх будинках створюють територію затишку і відпочинку, а ресторани та готелі приваблюють більше клієнтів.'
      },
    component: () => import(/* webpackChunkName: "balcony-gardening" */ '../views/BalconyGardening.vue')
  },
  {
    path: '/landscape-design',
    name: 'greening-of-offices',
     meta: {
        auth: false,
        title: 'Озеленення прибудинкової чи комерційної території, висадка дерев у саду | Lemberg Flowers',
        description:'Ви можете спробувати зробити ландшафтний дизайн своїми руками, витративши на це багато часу та коштів, і переживати за результат. Або замовити наші послуги і насолоджуватись красою озелененої території'
      },
    component: () => import(/* webpackChunkName: "greening-of-offices" */ '../views/LandscapeDesign.vue')
  },
  {
    path: '/mourning-floristics',
    name: 'mourning-floristics',
     meta: {
        auth: false,
        title: 'Траурна флористика. Квіткові композиції на похорон – це екологічна альтернатива пластиковим вінкам | Lemberg Flowers',
        description:'Такий вид флористики має певні правила, тому краще звернутися до професіоналів, щоб композиція виглядала доречною.'
      },
    component: () => import(/* webpackChunkName: "mourning-floristics" */ '../views/MourningFloristics.vue')
  },
  {
    path: '/photo-zone',
    name: 'photo-zone',
     meta: {
        auth: false,
        title: 'Фотозона у Садовому центрі Lemberg Flowers',
        description:'Організовуйте фотозйомки в нашому Садовому центрі серед квітів'
      },
    component: () => import(/* webpackChunkName: "photo-zone" */ '../views/PhotoZone.vue')
  },
  {
    path: '/kids-excursions',
    name: 'kids-excursions',
     meta: {
        auth: false,
        title: 'Організовуємо екскурсії для школярів | Lemberg Flowers',
        description:'Показуємо дітям наш садовий центр, та пояснюємо основні моменти та складнощі вирощування такої великої кількості рослин'
      },
    component: () => import(/* webpackChunkName: "kids-excursions" */ '../views/KidsExcursions.vue')
  },
  {
    path: '/events',
    name: 'events',
     meta: {
        auth: false,
        title: 'Події у садовому центрі Lemberg Flowers',
        description:'В Lemberg Flowers відбувається багато цікавого: день відкритих дверей, фотозони для фотосесій, екскурсії для школярів і багато іншого'
      },
    component: () => import(/* webpackChunkName: "events" */ '../views/Events.vue')
  },
  {
    path: '/payment-and-delivery',
    name: 'payment-and-delivery',
     meta: {
        auth: false,
        title: 'Придбати рослини з асортименту можна у нашому Садовому центрі за адресою с. Солонка, вул. Стрийська, 1Б | Lemberg Flowers',
        description:'У межах Львова доставляємо рослини попередньо замовлені для озеленення або на прохання доставки. Доставку по Україні розраховуємо індивідуально, залежно від типу та об\'єму замовлення.'
      },
    component: () => import(/* webpackChunkName: "payment-and-delivery" */ '../views/Delivery.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
     meta: {
        auth: false,
        title: 'Політика конфіденційності | Lemberg Flowers',
        description:'Політика конфіденційності | Lemberg Flowers'
      },
    component: () => import(/* webpackChunkName: "thank-you" */ '../views/Privacy.vue')
  },
   {
    path: '/thank-you',
    name: 'thank-you',
     meta: {
        auth: false,
        title: 'Дякуємо за звернення | Lemberg Flowers',
      },
    component: () => import(/* webpackChunkName: "thank-you" */ '../views/ThankYou.vue')
  },
  {
    path: '/:pathMatch(.*)',
    name: '404',
     meta: {
        auth: false,
        title: 'Сторінку незнайдено | Lemberg Flowers',
      },
    component: () => import(/* webpackChunkName: "404" */ '../views/PageNotFound.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  base: process.env.APP_URL,
  routes,
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if(to.hash){
      let elem = document.querySelector(`${to.hash}`)
      window.setTimeout(function(){
         elem.scrollIntoView({behavior: "smooth"})
      }, 500);
    } else {
      document.body.scrollTop = document.documentElement.scrollTop = 0
    }
  }
})

export default router
