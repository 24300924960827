<template>
  <header class="site-header"
  v-bind:class="{ main: mainHeader, hiddeHeader: currentRouteName == '404' }"
  >
      <div class="top-area" v-if="isMobile">
        <div>
          <div class="address">
            <a href="https://www.google.com/maps/dir//49.764711,24.0158236/@49.7652216,24.0162191,19z/data=!4m2!4m1!3e0" target="_blank" rel="noopener">вул.Стрийська, 1Б, с.Солонка, Львівська обл.</a>
          </div>
          <div>
            <a href="tel:+380960003888">+380 (96) 000 38 88</a>
          </div>
          <div class="icons">
            <a v-for="item in icons" :key="item" :href="item.link" target="_blank" rel="noopener">
              <img :src="item.url" :alt="item.name" width="26" height="26">
            </a>
           
          </div>
         
            <ul class="top-area-nav">
              <li><router-link to="/events">події</router-link></li>
              <li><router-link to="/payment-and-delivery">доставка</router-link></li>
              <li><router-link to="#contacts">Контакти</router-link></li>
            </ul>

        </div>
      </div>
      <div class="header" v-if="isMobile">
        <div class="logo">
          <router-link to="/">
            <img width="140" height="40" :src="require('/src/assets/images/Logo.svg')" alt="logo">
          </router-link>
          <span class="line"></span>
           <span class="logo-descript">Квіткове<br> господарство</span>
        </div>
        <header-nav :nav="mainNav" class="main-nav"></header-nav>
      </div>



      <div class="heder-fixed" :class="{ 'hidden-navbar': !showNavbar }">
        <div class="heder-fixed-wrap">
          <div class="logo">
            <router-link to="/">
              <img width="140" height="40" :src="require('/src/assets/images/Logo.svg')" alt="logo">
            </router-link>
          </div>
          <div class="phones" v-if="!isMobile">
                <a href="tel:+380960003888">+380 (96) 000 38 88</a>
              </div>
          <div class="burger-wrap"> 
            <div class="burger-nav" @click="showMobileMenu">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <transition name="fade">
            <div class="navbar" :class="{active : showMobNav}">
              <div class="burger-close" @click="showMobileMenu">
                <span></span>
                <span></span>
             </div>
             <div class="navbar-wrap">
                <header-nav :nav="fixedNav"></header-nav>
                <div class="icons" v-if="!isMobile">
                  <a v-for="item in icons" :key="item" :href="item.link" target="_blank" rel="noopener">
                    <img :src="item.url" :alt="item.name" width="26" height="26">
                  </a> 
                </div>
                <div class="phones">
                  <a href="tel:+380960003888">+380 (96) 000 38 88</a>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <transition name="bg-nav">
        <div class="bg-nav" v-if="showMobNav" @click="showMobileMenu"></div>
      </transition>
      {{addClass()}}

      <div class="pwa-bar" v-if="deferredPrompt" :class="{' ios-bar' : iOS}">
        <div class="info">
          <div class="icons-wrap">
           <img :src="require('/public/img/icons/android-launchericon-144-144.png')" width="48" height="48" alt="логотип сайту">
          <p>Lemberg Flowers</p>
          </div>
          <p v-if="iOS">Встановіть цей сайт на Ваш iPhon: натисніть <img :src="require('/src/assets/images/iphone_Action.png')" width="19" height="20" alt="iPhon іконка меню"> і тоді додати На Початковий екран (homescreen)</p>
            <p v-else>Встановити ярлик сайту на головний екран</p>

        </div>
        <div>
          <button class="pwa-button btn" @click="pwaButton" v-if="!iOS" >Встановити</button>
        </div>
        <button class="close-pwa" @click="dismissPwaButton" name="close pwa bar">X</button>
        </div>
  </header>
</template>

<script>
  import HeaderNav from '@/components/HeaderNav.vue'
export default {
 components: {
      'header-nav' : HeaderNav,
    },
  data() {
    return{
      mainNav: [
        {
          router:'/about',
          name:'Про нас',
          child:[]
        },
        {
          router:'/greening-of-offices',
          name:'Озеленення офісів',
          child:[]
        },
        {
          router:'/balcony-gardening',
          name:'Балконне озеленення',
          child:[]
        },
         {
          router:'/landscape-design',
          name:'Ландшафтний дизайн',
          child:[]
        },
        {
          router:'',
          name:'Спецпропозиції',
          child:[
            {
              router:'/mourning-floristics',
              name:'Траурна флористика',
            },
            {
              router:'/photo-zone',
              name:'Фотозона',
            },
            {
              router:'/kids-excursions',
              name:'Дитячі екскурсії',
            }
          ]
        }
      ],
      fixedNav: [
        {
          router:'/about',
          name:'Про нас',
          child:[]
        },
        {
          router:'/greening-of-offices',
          name:'Озеленення офісів',
          child:[]
        },
        {
          router:'/balcony-gardening',
          name:'Балконне озеленення',
          child:[]
        },
         {
          router:'/landscape-design',
          name:'Ландшафтний дизайн',
          child:[]
        },
        {
          router:'/events',
          name:'Події',
          child:[]
        },
        {
          router:'',
          name:'Спецпропозиції',
          child:[
            {
              router:'/mourning-floristics',
              name:'Траурна флористика',
            },
            {
              router:'/photo-zone',
              name:'Фотозона',
            },
            {
              router:'/kids-excursions',
              name:'Дитячі екскурсії',
            }
          ]
        },
        {
          router:'#contacts',
          name:'Контакти',
          child:[]
        }
      ],
      icons:[
        {    
          name: 'facebook',
          url: require('/src/assets/icons/premium-icon-faceboo.png'),
          link: 'https://www.facebook.com/lembergflowers.ltd'
        },
        { 
          name: 'instagram',
          url: require('/src/assets/icons/premium-icon-instagr.png'),
          link: 'https://www.instagram.com/lembergflowers'
         } 
      ],
      iconsMenu:[
        {    
          name: 'facebook',
          url: require('/src/assets/icons/facebook_menu.png'),
          link: 'https://www.facebook.com/lembergflowers.ltd'
        },
        { 
          name: 'instagram',
          url: require('/src/assets/icons/insta_menu.png'),
          link: 'https://www.instagram.com/lembergflowers'
         } 
      ],
      isMobile: false,
      windowSize: 0,
      mainHeader: false,
      showNavbar: false,
      showMobNav: false,
      lastScrollPosition: -300,
      scrollValue: 0,
      OFFSET: 60,
      deferredPrompt: null,
      iOS: null,
      showPWA: true,
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name
    },
  },
  watch: {
      '$route' () {
        this.showMobNav = false
        document.querySelector('html').style.overflowY='auto'
      }
  },

  mounted () {
    this.lastScrollPosition = window.pageYOffset
    window.addEventListener('scroll', this.onScroll)
    const viewportMeta = document.createElement('meta')
    viewportMeta.name = 'viewport'
    viewportMeta.content = 'width=device-width, initial-scale=1'
    document.head.appendChild(viewportMeta)

    this.anchorHashCheck()

    this.windowSize = window.innerWidth

    this.isMobileSize()

    this.isIos()

    this.captureEvent()
  },
  methods:{
    addClass:function(){
      if(this.currentRouteName == 'Home'){
        this.mainHeader = true
      }
      else {this.mainHeader = false}
    },
    anchorHashCheck(hash) {
      let url
      setTimeout(function(){
        if(hash){
          url = hash
        } else {
          url = window.location.hash
        }
        
        if (url) {
          let el = document.getElementById(window.location.hash.slice(1).substring(window.location.hash.slice(1).indexOf('#')+1))
          if (el) {
            el.scrollIntoView({behavior: "smooth"})
          }
        }
        },300)
         if(this.$route.hash !== "") {
              let elem = document.querySelector(`${this.$route.hash}`)
              window.setTimeout(function(){
                 elem.scrollIntoView({behavior: "smooth"})
              }, 500);
            }
      },
      onScroll () {
        if (window.pageYOffset < 0) {
          return
        }
        if (Math.abs(window.pageYOffset - this.lastScrollPosition) < this.OFFSET) {
          return
        }
        this.showNavbar = window.pageYOffset < this.lastScrollPosition
        this.lastScrollPosition = window.pageYOffset

         if(this.lastScrollPosition < 500 && this.isMobile) {
          this.showNavbar = window.pageYOffset > this.lastScrollPosition
         }
      
      },
      isMobileSize: function(){ 
         this.isMobile = this.windowSize < 1080 ? false : true
        
        this.showNavbar = this.windowSize < 1080 ? true : ''
      },
      showMobileMenu(){
        if(this.showMobNav){
          this.showMobNav = false
          document.querySelector('html').style.overflowY='auto'
        }
        else {
          this.showMobNav = true
          document.querySelector('html').style.overflowY='hidden'
        }
      },
      captureEvent() {
        if(!this.iOS) {
         window.addEventListener('beforeinstallprompt', (e) => {
            // Prevent Chrome 67 and earlier from automatically showing the prompt
            e.preventDefault()
            // Stash the event so it can be triggered later.
            this.deferredPrompt = e
            console.log(this.deferredPrompt)
          })
       } else {
            const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

            // Checks if should display install popup notification:
            if (!isInStandaloneMode() && !localStorage.showPWA) {
                  this.deferredPrompt = true;
                
            }
          }
        },
      isIos() {
        const userAgent = window.navigator.userAgent.toLowerCase();
        this.iOS = /iphone|ipad|ipod/.test( userAgent )
      },

      pwaButton() {
         // showPwaButton
          this.deferredPrompt.prompt()
          // Wait for the user to respond to the prompt
          this.deferredPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
              // Add analyticcs event
             this.$gtag.event('add_to_home_screen')
           }
            this.deferredPrompt = null
         })
        },
      dismissPwaButton() {
          this.deferredPrompt = null;

          localStorage.showPWA = false;
         }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only <style scoped lang="scss">-->

<style scoped lang="scss">

</style>
